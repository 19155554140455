import React from 'react';
// @mui
import { Grid, Button } from '@mui/material';
import Swal from 'sweetalert2';
// components
import Iconify from '../../../componentes/Iconify';
import RDateField from '../../../componentes/forms/inputs/RDateField';
import RSelect from '../../../componentes/forms/inputs/RSelect';

import { buildBody, isEmpty, getUniqueKey, workWithLocalStorage, compruebaForm } from '../../../auxiliares/funciones';
import { getAusencias } from '../../../auxiliares/clases/contratos';
import CONFIG from '../../../CONFIG.json';
import RTabla from '../../../componentes/tabla';

// ----------------------------------------------------------------------

export default function AusenciaForm({ showLoader, contrato, setCargadoC }) {   

  const Form = { fechaInicio:'',fechaFin:'',motivo:'' };
  const [ausencia, setAusencia] = React.useState(Form);
  const [ausencias, setAusencias] = React.useState([]);
  const [cargado, setCargado] = React.useState(false);
  const [key, setKey] = React.useState(getUniqueKey());
  const usuario = workWithLocalStorage('get','usuario',null);  
  const obligatorios = ['fechaInicio','fechaFin','motivo'];

  React.useEffect(() => {
    async function cargaInicial(){
        if(!cargado){         
            setAusencias(await getAusencias(contrato.id));
            setCargado(true);
        }
    }
    cargaInicial();
  // eslint-disable-next-line
  },[cargado]);

  const actualizaCampo = (input) =>{
    const nombre = input.target.name
    const valor = input.target.value
    const ausenciaA = ausencia;
    ausenciaA[nombre] = valor
    setAusencia(ausenciaA);
  }

  const Grabar = () =>{
    const valido = compruebaForm(Form,ausencia,obligatorios);
    if(valido){
        // eslint-disable-next-line react/prop-types
        showLoader(true); 
        const body = ausencia;
        body.idContrato = contrato.id;
        body.idUsuarioAlta = usuario.id;
        const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
        fetch(`${CONFIG.api}contrato/ausencia`,{
                method: 'PUT',
                body: bodyJson,
                headers: {'Content-Type': 'application/json'}
        })
        .then(res => res.json())
        .then((result) => {
            let msg ='Ausencia grabada!';
            if (result.error === 0) {   
                setCargado(false);
                setKey(getUniqueKey());            
            }else{
                msg = result.errorMessage;
            }
            showLoader(false); 
            Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
            })
        })
    }else{
        Swal.fire({
            title: 'Revisa los campos con *',
            buttons: [{label: 'Ok'}]
        })
    }
  }

  const RecalcularFin = () =>{
    // eslint-disable-next-line react/prop-types
    showLoader(true); 
    const body = {};
    body.id = contrato.id;
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
    fetch(`${CONFIG.api}contrato/recalcularFin`,{
            method: 'PUT',
            body: bodyJson,
            headers: {'Content-Type': 'application/json'}
    })
    .then(res => res.json())
    .then((result) => {
        let msg ='Fecha recalculada!';
        if (result.error === 0) {   
            setCargadoC(false);          
        }else{
            msg = result.errorMessage;
        }
        showLoader(false); 
        Swal.fire({
          title: msg,
          buttons: [{label: 'Ok'}]
        })
    })
}

  /* ***************** TABLA ************************** */
  const campos = ['fechaInicio','fechaFin','motivo'];    
  const cabecera = ['Desde','Hasta','Motivo'];

  const BorrarAusencia = (obj) =>{
    // eslint-disable-next-line react/prop-types
    showLoader(true); 
    const body = {
      tabla: "contratos_ausencias",
      activo: 0,
      usuario: usuario.id,
      id: obj.id
    }
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
    fetch(`${CONFIG.api}master/activo`,{
              method: 'PUT',
              body: bodyJson,
              headers: {'Content-Type': 'application/json'}
      })
      .then(res => res.json())
      .then((result) => {
        let msg ='Ausencia borrada!';
          if (result.error === 0) {      
            setCargado(false);
            setKey(getUniqueKey());
          }else{
            msg = result.errorMessage;
          }
          Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
          })
    })
}

const DocAusencia = () =>{
    // eslint-disable-next-line react/prop-types
    showLoader(true);
    const body = {
      usuario: usuario.id,
      id: contrato.id,
      idcontrato: contrato.id
    }
    const bodyJson = JSON.stringify(buildBody(CONFIG.TOKEN,body))
    fetch(`${CONFIG.api}contrato/ausenciaDoc`,{
              method: 'POST',
              body: bodyJson,
              headers: {'Content-Type': 'application/json'}
      })
      .then(res => res.json())
      .then((result) => {
        let msg ='Documento generado!';
          if (result.error !== 0) {
            msg = result.errorMessage;
          }
          showLoader(false);
          Swal.fire({
            title: msg,
            buttons: [{label: 'Ok'}]
          })
    })
}

  const acciones = [{
      color:'error',
      nombre:'Borrar',
      icono:<i />,
      funcion: BorrarAusencia,
      estilo: {},
      condition: null
    }]

  return (
    <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
        <Grid item md={6}>
            <form style={{ width: '100%'}} >
                <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                    <RSelect name="motivo" label="Motivo*" actualizaCampo={actualizaCampo} value={ausencia.motivo} md={4}
                    values={[{id:'Vacaciones',nombre:'Vacaciones'},{id:'Baja Médica',nombre:'Baja Médica'},{id:'ERTE',nombre:'ERTE'}]}/>
                    <RDateField name="fechaInicio" label="Fecha Inicio*" actualizaCampo={actualizaCampo} value={ausencia.fechaInicio} md={4} />
                    <RDateField name="fechaFin" label="Fecha Fin*" actualizaCampo={actualizaCampo} value={ausencia.fechaFin} md={4} />
                </Grid>
                <Grid container spacing={1} sx={{ marginBottom: '30px' }}>
                    <Button variant="contained" color="success" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={()=>Grabar()}> Grabar </Button>
                    <Button variant="contained" color="warning" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:calendar-outline" />}
                    onClick={()=>RecalcularFin()}> Recalcular fecha fin </Button>
                    <Button variant="contained" color="primary" sx={{ color: 'white', marginRight: '15px'}} to="#" startIcon={<Iconify icon="eva:calendar-outline" />}
                    onClick={()=>DocAusencia()}> Documento Vacaciones </Button>
                </Grid>
            </form>
        </Grid>        
        {!isEmpty(ausencias) &&
        <Grid item md={6}>
            <RTabla key={key} lista={ausencias} campos={campos} cabecera={cabecera} acciones={acciones}/>
        </Grid>
        }
    </Grid>
  );
}
